import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/about-us": [3],
		"/category/[idOrCode]/[...slug]": [~4],
		"/contact-us": [5],
		"/content": [~6],
		"/content/[idOrCode]": [7],
		"/content/[idOrCode]/[...slug]": [~8],
		"/faq": [9],
		"/how-we-can-work-together": [10],
		"/news": [~11],
		"/post-call/[linkCode]/[...rest]": [~12],
		"/publishers/[idOrCode]/[...slug]": [~13],
		"/search": [~14],
		"/terms-and-conditions": [15],
		"/thanks": [16],
		"/vendors/[idOrCode]/[...slug]": [~17],
		"/webinar": [~18],
		"/work-with-us": [19]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';